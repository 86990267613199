.flex {
    display: flex;
}

.flex1 {
    flex: 1;
}

.flex2 {
    flex: 2;
}

.flex3 {
    flex: 3;
}

.flex4 {
    flex: 4;
}

.flex5 {
    flex: 5;
}

.flexWrap {
    flex-wrap: wrap;
}

.column {
    flex-direction: column;
}

.center {
    align-items: center;
    justify-content: center;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.justify-start {
    justify-content: flex-start;
}

.vh100 {
    height: 100vh;
}

.w100 {
    width: 100%;
}

.h100 {
    height: 100% !important;
}

.scrollY {
    overflow-y: scroll;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.relative {
    position: relative;
}

.marginTop5 {
    margin-top: 5px;
}

.marginTop10 {
    margin-top: 10px;
}

.marginTop15 {
    margin-top: 15px;
}

.marginTop20 {
    margin-top: 20px;
}

.marginTop30 {
    margin-top: 30px;
}

.marginTop40 {
    margin-top: 40px;
}

.marginTop50 {
    margin-top: 50px;
}

.marginTop60 {
    margin-top: 60px;
}

.marginTop100 {
    margin-top: 100px;
}

.marginBottom5 {
    margin-bottom: 5px;
}

.marginBottom10 {
    margin-bottom: 10px;
}

.marginBottom15 {
    margin-bottom: 15px;
}

.marginBottom20 {
    margin-bottom: 20px;
}

.marginBottom25 {
    margin-bottom: 25px;
}

.marginBottom30 {
    margin-bottom: 30px;
}

.marginRight5 {
    margin-right: 5px;
}

.marginRight10 {
    margin-right: 10px;
}

.marginRight15 {
    margin-right: 15px;
}

.marginRight20 {
    margin-right: 20px;
}

.marginRight25 {
    margin-right: 25px;
}

.marginRight30 {
    margin-right: 30px;
}

.marginRight35 {
    margin-right: 35px;
}

.marginRight40 {
    margin-right: 40px;
}

.marginRight45 {
    margin-right: 45px;
}

.marginLeft5 {
    margin-left: 5px;
}

.marginLeft10 {
    margin-left: 10px;
}

.marginLeft15 {
    margin-left: 15px;
}

.marginLeft20 {
    margin-left: 20px;
}

.marginLeft25 {
    margin-left: 25px;
}

.marginLeft30 {
    margin-left: 30px;
}

.marginLeft35 {
    margin-left: 35px;
}

.marginLeft40 {
    margin-left: 40px;
}

.marginLeft45 {
    margin-left: 45px;
}

.text-align-left {
    text-align: left;
}

.text-align-riht {
    text-align: left;
}

.text-align-center {
    text-align: center;
}

.bold {
    font-weight: bold;
}

